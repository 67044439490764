import { BaseSyntheticEvent, FC, useEffect } from "react";
import { MerchantType, createMerchant, updateMerchant } from "../../../api/merchants";
import { Dialog } from "primereact/dialog";
import { Controller, FieldValues, useForm } from "react-hook-form";
import { InputText } from "primereact/inputtext";
import { InputNumber } from "primereact/inputnumber";
import { Button } from "primereact/button";
import { classNames } from "primereact/utils";
import { toast } from "react-toastify";

interface props {
    merchant?: MerchantType;
    isOpen: boolean;
    closeModal: () => void;
    refetch: () => void;
}
const CreateModal: FC<props> = ({merchant, isOpen, closeModal, refetch}) => {
    const header = merchant ? 'Lager bearbeiten' : 'Lager anlegen';
    const initialValues = merchant ? merchant : {
        name: '',
        latitude: 0,
        longitude: 0,
        maxDeliveryDistance: 25,
        isOpen: false,
    }
    const {
        control,
        formState: { errors },
        handleSubmit,
        reset
    } = useForm<MerchantType>({defaultValues: initialValues})
    useEffect(() => {
        if(merchant) {
            reset(merchant);
        }
    }, [merchant, reset])

    const onSubmit = async (data: FieldValues, e?: BaseSyntheticEvent) => {
        try {
            if (merchant) {
                await updateMerchant({id: merchant.id, ...data as MerchantType});
            } else {
                await createMerchant(data as MerchantType);
            }
            refetch();
            closeModal();
        } catch (err) {
            toast.error(err as string);
        }
    };

    return (
        <Dialog header={header} visible={isOpen} style={{width: '50vw'}} onHide={closeModal}>
                <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col pt-2 w-full gap-4">
                    <Controller name={"name"} control={control} render={({ field, fieldState }) => (
                        <>
                            <label htmlFor={field.name} className={classNames({ 'p-error': errors.name })}></label>
                            <span className="p-float-label w-full">
                                <InputText id={field.name} value={field.value} className={classNames({ 'p-invalid': fieldState.error }, 'w-full')} onChange={(e) => field.onChange(e.target.value)} />
                                <label htmlFor={field.name}>Lager Name</label>
                            </span>
                        </>
                    )} />

                    <Controller name={"latitude"} control={control} render={({ field, fieldState }) => (
                        <>
                            <label htmlFor={field.name} className={classNames({ 'p-error': errors.latitude })}></label>
                            <span className="p-float-label">
                                <InputNumber showButtons id={field.name} value={field.value} className={classNames({ 'p-invalid': fieldState.error }, 'w-full')} onChange={(e) => field.onChange(e.value)} />
                                <label htmlFor={field.name}>Breitengrad</label>
                            </span>
                        </>
                    )} />

                    <Controller name={"longitude"} control={control} render={({ field, fieldState }) => (
                        <>
                            <label htmlFor={field.name} className={classNames({ 'p-error': errors.longitude })}></label>
                            <span className="p-float-label">
                                <InputNumber showButtons id={field.name} value={field.value} className={classNames({ 'p-invalid': fieldState.error }, 'w-full')} onChange={(e) => field.onChange(e.value)} />
                                <label htmlFor={field.name}>Längengrad</label>
                            </span>
                        </>
                    )} />

                    <Controller name={"maxDeliveryDistance"} control={control} render={({ field, fieldState }) => (
                        <>
                            <label htmlFor={field.name} className={classNames({ 'p-error': errors.maxDeliveryDistance })}></label>
                            <span className="p-float-label">
                                <InputNumber showButtons id={field.name} value={field.value} className={classNames({ 'p-invalid': fieldState.error }, 'w-full')} onChange={(e) => field.onChange(e.value)} />
                                <label htmlFor={field.name}>Delivery Distance</label>
                            </span>
                        </>
                    )} />
                    
                    <Button label="Speichern" type="submit" icon="pi pi-check" />
                </form>
        </Dialog>
    )
}

export default CreateModal;