import { BaseSyntheticEvent, FC, useEffect } from "react";
import { Product, createProduct, updateProduct } from "../../../api/products";
import { Dialog } from "primereact/dialog";
import { Controller, FieldValues, useForm } from "react-hook-form";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { classNames } from "primereact/utils";
import { toast } from "react-toastify";
import { Editor } from "primereact/editor";

interface props {
    product?: Product;
    isOpen: boolean;
    closeModal: () => void;
    refetch: () => void;
}
const CreateModal: FC<props> = ({product, isOpen, closeModal, refetch}) => {
    const header = product ? 'Produkt bearbeiten' : 'Produkt anlegen';
    const initialValues = product ? product : {
        handle: '',
        title: '',
        vendor: '',
        descriptionHtml: '',
    }
    const {
        control,
        formState: { errors },
        handleSubmit,
        reset
    } = useForm<Product >({defaultValues: initialValues})
    useEffect(() => {
        if(product) {
            reset(product);
        }
    }, [product, reset])

    const onSubmit = async (data: FieldValues, e?: BaseSyntheticEvent) => {
        try {
            if (product) {
                await updateProduct({id: product.id, ...data as Product});
            } else {
                await createProduct(data as Product);
            }
            refetch();
            closeModal();
        } catch (err) {
            toast.error(err as string);
        }
    };

    return (
        <Dialog header={header} visible={isOpen} style={{width: '50vw'}} onHide={closeModal}>
                <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col pt-2 w-full gap-4">
                    <Controller name={"title"} control={control} render={({ field, fieldState }) => (
                        <>
                            <label htmlFor={field.name} className={classNames({ 'p-error': errors.title })}></label>
                            <span className="p-float-label">
                            <InputText id={field.name} value={field.value} className={classNames({ 'p-invalid': fieldState.error }, 'w-full')} onChange={(e) => field.onChange(e.target.value)} />
                                <label htmlFor={field.name}>Name</label>
                            </span>
                        </>
                    )} />

                    <Controller name={"vendor"} control={control} render={({ field, fieldState }) => (
                        <>
                            <label htmlFor={field.name} className={classNames({ 'p-error': errors.vendor })}></label>
                            <span className="p-float-label">
                                <InputText id={field.name} value={field.value} className={classNames({ 'p-invalid': fieldState.error }, 'w-full')} onChange={(e) => field.onChange(e.target.value)} />
                                <label htmlFor={field.name}>Hersteller</label>
                            </span>
                        </>
                    )} />

                    <Controller name={"descriptionHtml"} control={control} render={({ field, fieldState }) => (
                        <span className="p-label">
                            <label htmlFor={field.name} className={classNames({ 'p-error': errors.descriptionHtml }, 'pt-8')}>Produktbeschreibung</label>
                            <Editor id={field.name}  style={{ height: '220px' }} value={field.value} className={classNames({ 'p-invalid': fieldState.error }, 'w-full')} onTextChange={(e) => field.onChange(e.htmlValue)} />
                        </span>
                    )} />
                    
                    <Button label="Speichern" type="submit" icon="pi pi-check" />
                </form>
        </Dialog>
    )
}

export default CreateModal;