import React, { FC } from "react";
import { orderlordSyncMerchant } from "../../api/merchants";
import { Dialog } from "primereact/dialog";
import { Calendar } from "primereact/calendar";
import { Button } from "primereact/button";

interface props {
    merchantId: number;
    isOpen: boolean;
    closeModal: () => void;
}

const SyncOrdersModal: FC<props> = ({merchantId, isOpen, closeModal}) => {
    const [lastOrderSync, setLastOrderSync] = React.useState<Date>(new Date()); 

    const onSubmit = async () => {
        if(merchantId) {
            console.log(lastOrderSync.toISOString());
            const newMerchant = await orderlordSyncMerchant(merchantId, true, lastOrderSync.toISOString()).catch((e) => console.error(e));
            if (newMerchant) closeModal();
        }
    }
    return <Dialog header={'Aktiviere die Order Syncronisation'} visible={isOpen} style={{width: '50vw'}} onHide={closeModal}>
        <span className="p-float-label w-full">
            <Calendar id={'lastOrderSync'} value={lastOrderSync} className={'w-full'} onChange={(e) => setLastOrderSync(e.value as Date)} showTime />
            <label htmlFor={'lastOrderSync'}>Orders Ab</label>
        </span>
        <Button className="mt-2 p-4 bg-primary flex justify-center items-center text-white" label="Speichern" onClick={onSubmit} icon="" />
    </Dialog>;
}
export default SyncOrdersModal;