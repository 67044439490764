import { FC, useEffect, useState } from "react";
import { Dialog } from "primereact/dialog";
import { CSVLink } from "react-csv";
import { Importer, ImporterField } from "react-csv-importer";
import 'react-csv-importer/dist/index.css';
import { bulkUpdateMerchantProduct, getStockLevels } from "../../../../api/products";
import { Divider } from "primereact/divider";

interface props {
    merchantId: number;
    isOpen: boolean;
    closeModal: () => void;
    refetch: () => void;
}

const BulkProductActionsModal: FC<props> = ({merchantId, isOpen, closeModal, refetch}) => {
    const [productCSV, setProductCSV] = useState<any[]>([]);

    useEffect(() => {
        const setProductData = async () => {
            const response = await getStockLevels({filters: {merchantId}, pagination: {page: 1, perPage: 1000, pageCount: 0, total: 0}});
            if(response) {
                const {data} = response;
                const products = data.map(({sku, price, amount, triggerAmount, targetAmount, title, tags}) => ({
                    sku: sku.split('_')[0],
                    price,
                    amount,
                    triggerAmount,
                    targetAmount,
                    title,
                    tags
                }));
                setProductCSV(products);
            }
        }
        if(productCSV.length === 0 && isOpen) setProductData();
    }, [isOpen, productCSV.length, merchantId]);
    const headerCSV = [
        {label: 'SKU', key: 'sku'},
        {label: 'Preis', key: 'price'},
        {label: 'Bestand', key: 'amount'},
        {label: 'Triggerbestand', key: 'triggerAmount'},
        {label: 'Sollbestand', key: 'targetAmount'},
        {label: "Titel", key: 'title'},
        {label: "Tags", key: 'tags'}
    ];

    const dataHandler = async (rows: any[]) => {
        if(!merchantId) return;
        const stockLevels = rows.map(({sku, price, amount, triggerAmount, targetAmount}) => ({
            sku,
            price: price && price !== '' ? price : undefined,
            amount: amount && amount !== '' ? amount : undefined,
            triggerAmount: triggerAmount && triggerAmount !== '' ? triggerAmount : undefined,
            targetAmount: targetAmount && targetAmount !== '' ? targetAmount : undefined,
        }));
        await bulkUpdateMerchantProduct(`${merchantId}`, {stockLevels});
        refetch();
        closeModal();
    };

    const header = <div className="flex justify-start items-center">
        <h2>Bulk Aktionen</h2>
    </div>;

    const footer = <div className="flex justify-between items-center">
        <CSVLink
            className="mr-2 relative inline-flex items-center rounded-md bg-midblue border-midblue border px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-transparent hover:text-midblue focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-midblue"
            data={productCSV}
            headers={headerCSV}
            filename="products.csv"
        >Download CSV</CSVLink>
        <Divider layout="vertical" />
        <button
            className="mr-2 relative inline-flex items-center rounded-md bg-midblue border-midblue border px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-transparent hover:text-midblue focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-midblue"
            onClick={closeModal}
        >Close</button>
    </div>;



    return <Dialog header={header} footer={footer} visible={isOpen} style={{width: '50vw'}} onHide={closeModal}>
        <Importer 
            dataHandler={dataHandler} 
            onComplete={({ file, preview, fields, columnFields }) => {}} 
            onClose={({ file, preview, fields, columnFields }) => {}}
        >
            <ImporterField name="sku" label="SKU" />
            <ImporterField optional name="price" label="Preis" />
            <ImporterField optional name="amount" label="Bestand" />
            <ImporterField optional name="triggerAmount" label="Triggerbestand" />
            <ImporterField optional name="targetAmount" label="Sollbestand" />
        </Importer>
    </Dialog>;
};
export default BulkProductActionsModal;