import { FC } from "react";
import { Dialog } from "primereact/dialog";
import { CSVLink } from "react-csv";
import { Importer, ImporterField } from "react-csv-importer";
import 'react-csv-importer/dist/index.css';
import { Divider } from "primereact/divider";
import { bulkUpdateMerchantZipcodes } from "../../../../api/merchants";

interface props {
    merchantId: number;
    isOpen: boolean;
    closeModal: () => void;
    zipcodes?: any[];
    setZipcodes: (zipcodes: any[]) => void;
    setLoading: (loading: boolean) => void;
}

const BulkZipcodeActionsModal: FC<props> = ({merchantId, isOpen, closeModal, setZipcodes, zipcodes, setLoading}) => {

    const headerCSV = [
        {label: 'Zipcode', key: 'zipcode'},
        {label: 'City', key: 'city'},
        {label: 'Mindestbestellwert', key: 'minOrderAmount'},
        {label: 'Lieferkosten', key: 'deliveryFee'},
    ];

    const dataHandler = async (rows: any[]) => {
        if(!merchantId) return;
        setLoading(true);
        const zipCodes = rows.map((payload) => {
            const {zipcode, city, minOrderAmount, deliveryFee} = payload;
            return {
                    zipcode,
                    city,
                    minOrderAmount: minOrderAmount && minOrderAmount !== '' ? Math.round(Number(minOrderAmount) * 100) : 1900,
                    deliveryFee: deliveryFee && deliveryFee !== '' ? Math.round(Number(deliveryFee) * 100) : 285,
            };
        });
        const newZipcodes = await bulkUpdateMerchantZipcodes(merchantId, zipCodes);
        setZipcodes([...(zipcodes || []), ...newZipcodes]);
        setLoading(false);
        closeModal();
    };

    const header = <div className="flex justify-start items-center">
        <h2>Bulk Aktionen</h2>
    </div>;

    const footer = <div className="flex justify-between items-center">
        <CSVLink
            className="mr-2 relative inline-flex items-center rounded-md bg-midblue border-midblue border px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-transparent hover:text-midblue focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-midblue"
            data={zipcodes || []}
            headers={headerCSV}
            filename="zipcodes.csv"
        >Download CSV</CSVLink>
        <Divider layout="vertical" />
        <button
            className="mr-2 relative inline-flex items-center rounded-md bg-midblue border-midblue border px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-transparent hover:text-midblue focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-midblue"
            onClick={closeModal}
        >Close</button>
    </div>;



    return <Dialog header={header} footer={footer} visible={isOpen} style={{width: '50vw'}} onHide={closeModal}>
        <Importer 
            dataHandler={dataHandler} 
            onComplete={({ file, preview, fields, columnFields }) => {}} 
            onClose={({ file, preview, fields, columnFields }) => {}}
        >
            <ImporterField name="zipcode" label="Zipcode" />
            <ImporterField optional name="city" label="City" />
            <ImporterField optional name="minOrderAmount" label="Mindestbestellwert" />
            <ImporterField optional name="deliveryFee" label="Lieferkosten" />
        </Importer>
    </Dialog>;
};
export default BulkZipcodeActionsModal;