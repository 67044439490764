import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css'
import 'primeicons/primeicons.css';
import 'react-toastify/dist/ReactToastify.css';
import Router from "./router/Router";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import { PrimeReactProvider } from "primereact/api";


const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    <React.StrictMode>
        <PrimeReactProvider>
        <Router/>
        </PrimeReactProvider>
    </React.StrictMode>
);


