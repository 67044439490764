import {Navigate, Outlet} from 'react-router-dom';
import Cookies from "universal-cookie";
import Header from "../components/Header";
import Sidebar from "../components/Sidebar";
import {useState} from "react";
import {Provider} from "react-redux";
import {store} from "../redux/store";
import {ROUTES_DEFINITIONS} from "./routerConfig";

const cookies = new Cookies();
const PrivateRoutes = () => {
    const token = cookies.get("MIDNIGHT_DELIVERY_TOKEN");
    const [sidebarOpen, setSidebarOpen] = useState(false)
    return (
        token ?
            <Provider store={store}>
                <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen}/>
                <div className="lg:pl-72">
                        <Header setSidebarOpen={setSidebarOpen}/>
                        <main className="p-4 sm:p-6 lg:p-8">
                            <Outlet/>
                        </main>
                </div>
            </Provider>
            : <Navigate to={ROUTES_DEFINITIONS.Login}/>
    );
};
export default PrivateRoutes;
