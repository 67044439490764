import { BaseSyntheticEvent, FC, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { Controller, FieldValues, useForm } from "react-hook-form";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { classNames } from "primereact/utils";
import { toast } from "react-toastify";
import { MerchantType, updateMerchant, createMerchant } from "../../api/merchants";

interface props {
    merchant?: MerchantType;
    isOpen: boolean;
    closeModal: () => void;
    setMerchant?: (merchant: MerchantType) => void;
    setLoading?: (loading: boolean) => void;
}
const EditMerchantModal: FC<props> = ({merchant, isOpen, closeModal, setMerchant, setLoading}) => {
    const header = merchant ? 'Lager bearbeiten' : 'Lager anlegen';
    const initialValues = merchant ? merchant : {
        name: '',
        contractor: '',
        orderlordId: undefined,
        orderlordVenueId: undefined,
    }
    const {
        control,
        formState: { errors },
        handleSubmit,
        reset
    } = useForm<MerchantType>({defaultValues: initialValues})
    useEffect(() => {
        if(merchant) {
            reset(merchant);
        }
    }, [merchant, reset])

    const onSubmit = async (data: FieldValues, e?: BaseSyntheticEvent) => {
        setLoading && setLoading(true);
        try {
            const newMerchant = await(merchant ? updateMerchant({id: merchant.id, ...data as MerchantType}) : createMerchant(data as MerchantType));
            setMerchant && newMerchant && setMerchant(newMerchant);
        } catch (err) {
            toast.error(err as string);
        } finally {
            setLoading && setLoading(false);
            closeModal();
        }
    };

    return (
        <Dialog header={header} visible={isOpen} style={{width: '50vw'}} onHide={closeModal}>
                <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col pt-8 w-full gap-8">
                    <div className="w-full">
                    <Controller name={"name"} control={control} render={({ field, fieldState }) => (
                        <>
                            <label htmlFor={field.name} className={classNames({ 'p-error': errors.name })}></label>
                            <span className="p-float-label w-full">
                                <InputText id={field.name} value={field.value} className={classNames({ 'p-invalid': fieldState.error }, 'w-full')} onChange={(e) => field.onChange(e.target.value)} />
                                <label htmlFor={field.name}>Lager Name</label>
                            </span>
                        </>
                    )} />
                    </div>
                    <div className="w-full">
                    <Controller name={"contractor"} control={control} render={({ field, fieldState }) => (
                        <>
                            <label htmlFor={field.name} className={classNames({ 'p-error': errors.contractor })}></label>
                            <span className="p-float-label w-full">
                                <InputText id={field.name} value={field.value} className={classNames({ 'p-invalid': fieldState.error }, 'w-full')} onChange={(e) => field.onChange(e.target.value)} />
                                <label htmlFor={field.name}>Contractor</label>
                            </span>
                        </>
                    )} />
                    </div>
                    <div className="w-full flex flex-row justify-between">
                    <Controller name={"orderlordId"} control={control} render={({ field, fieldState }) => (
                        <>
                            <label htmlFor={field.name} className={classNames({ 'p-error': errors.orderlordId })}></label>
                            <span className="p-float-label w-full">
                                <InputText id={field.name} value={field.value} className={classNames({ 'p-invalid': fieldState.error }, 'w-full')} onChange={(e) => field.onChange(e.target.value)} />
                                <label htmlFor={field.name}>Orderlord Store Id</label>
                            </span>
                        </>
                    )} />
                    <Controller name={"orderlordVenueId"} control={control} render={({ field, fieldState }) => (
                        <>
                            <label htmlFor={field.name} className={classNames({ 'p-error': errors.orderlordVenueId })}></label>
                            <span className="p-float-label w-full">
                                <InputText id={field.name} value={field.value} className={classNames({ 'p-invalid': fieldState.error }, 'w-full')} onChange={(e) => field.onChange(e.target.value)} />
                                <label htmlFor={field.name}>Orderlord Venue Id</label>
                            </span>
                        </>
                    )} />
                    </div>
                    
                    <Button className="mt-2 p-4 bg-primary flex justify-center items-center text-white" label="Speichern" type="submit" icon="" />
                </form>
        </Dialog>
    )
}

export default EditMerchantModal;