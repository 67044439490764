import { Button } from 'primereact/button'
import { InputText } from 'primereact/inputtext'
import { InputTextarea } from 'primereact/inputtextarea'
import { SelectButton } from 'primereact/selectbutton'
import { FC } from 'react'
        const HelpDesk : FC = () => {
            return (
                <form method="post" action="https://midnytb2bch.weclapp.com/webapp/seam/resource/rest/web2ticket?charset=UTF-8" accept-charset="UTF-8" encType="multipart/form-data">
                <div className="w-full flex gap-2 justify-between items-center p-2">
                    <div className="w-1/2 flex flex-col">
                <label htmlFor="idWC_subject">Betreff</label><br />
                <InputText className='w-full border' id="idWC_subject" name="subject" maxLength={150} /><br />
                </div>
                <div className="flex flex-col">
                <label htmlFor="idWC_priority">Priorität</label><br />
                <SelectButton className='w-full border' id="idWC_priority" name="priority" options={['niedrig', 'normal', 'hoch']}  /><br />
                </div>
                </div>
                <div className="w-full flex flex-col justify-center p-2">
                <label htmlFor="idWC_description">Beschreibung</label><br />
                <InputTextarea className='w-full border' id="idWC_description" name="description" rows={3} /><br />
                </div>
                <div className="w-full flex gap-2  justify-between items-center p-2">
                    <div className="flex-grow flex flex-col">
                <label htmlFor="idWC_firstname">Vorname</label>
                <InputText className='w-full border' id="idWC_firstname" name="firstname" maxLength={1000} /><br />
                </div>
                <div className="flex-grow flex flex-col">
                <label htmlFor="idWC_lastName">Nachname</label><br/>
                <InputText className='w-full border' id="idWC_lastName" name="lastName" maxLength={1000} /><br />
                </div></div>
                <div className="w-full flex gap-2 justify-between items-center p-2">
                    <div className="flex-grow flex flex-col">
                <label htmlFor="idWC_email">E-Mail</label>
                <InputText className='w-full border' type='email' id="idWC_email" name="email" maxLength={1000} /><br />
                </div>
                <div className="flex-grow flex flex-col">
                <label htmlFor="idWC_mobile">Mobil</label><br/>
                <InputText className='w-full border' id="idWC_mobile" name="mobile" maxLength={1000} /><br />
                </div></div>
                <div className="w-full flex justify-between items-center p-2">
                    <div className="flex-grow flex flex-col">
                <label htmlFor="idWC_documents">Dokumente</label><br/>
                <input type="file" id="idWC_documents" name="documents" multiple /><br />
                </div></div>
                <input type="hidden" name="honeypot" value="" /><br />
                <input type="hidden" name="token" value="30bd5bc9-70ad-4a11-b354-8a0ac3362884" /><br />
                <Button label="Speichern" type="submit" icon="pi pi-check" />
                </form>
            )
        }
        export default HelpDesk
        