import { FC, useEffect, useState } from "react";
import { getMerchants, MerchantType } from "../../api/merchants";
import { attachProductToMerchant, ProductListItem, StockLevel } from "../../api/products";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";

interface props {
    isOpen: boolean;
    product: ProductListItem;
    closeModal: () => void;
}

const ConnectedMerchantModal: FC<props> = ({isOpen, product, closeModal}) => {
    const [merchants, setMerchants] = useState<MerchantType[]>();
    const [connectedProducts, setConnectedProducts] = useState<StockLevel[]>(product.stockLevels || []);

    useEffect(() => {
        const fetchMerchants = async () => {
            const response = await getMerchants();
            setMerchants(response);
        }
        fetchMerchants();
    }, [product.stockLevels])

    const attachProduct = async (merchantId: number) => {
        await attachProductToMerchant(product.variantId, merchantId);
        setConnectedProducts([...connectedProducts, {
            merchantId,
            id: 0,
            variantId: Number(product.variantId),
            price: product.price,
            amount: product.amount,
            targetAmount: 0,
            triggerAmount: 0,
            targetRelation: 0,
            isActive: true,
        }]);
    }
    return (
        <Dialog header={`Anbieter für ${product.title}`} visible={isOpen} style={{width: '50vw'}} onHide={closeModal}>
            <div className="flex flex-col gap-4">
                <table>
                {merchants?.map((merchant) => {
                    const connectedProduct = connectedProducts.find(({merchantId}) => merchant.id === merchantId);
                    return (
                        <tr>
                            <td>{merchant.name}</td>
                            <td>{connectedProduct ? 'Verbunden' : (<Button label={'Product erstellen'} onClick={() => attachProduct(merchant.id || 0)} />)}</td>
                            <td></td>
                        </tr>
                    )
                })}
                </table>
            </div>
        </Dialog>
    )
}

export default ConnectedMerchantModal;