import React, { useEffect, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { getOrderlordStores } from "../../../../api/orderlord";
import { MerchantType, updateMerchant, orderlordSyncMerchant } from "../../../../api/merchants";
import { Button } from "primereact/button";
import SyncOrdersModal from "../../../../components/Modals/SyncOrdersModal";

interface props {
 isAdmin: boolean;
 merchant: MerchantType;
 merchants?: MerchantType[];
 setMerchant: (merchant: MerchantType) => void;
}
const MerchantOrderlord: React.FC<props> = ({merchants, merchant, setMerchant}) => {
    const [stores, setStores] = useState<any[]>();
    const [openSyncModal, setOpenSyncModal] = useState<boolean>(false);
    const [pagination, setPagination] = useState({limit: 10, offset: 0, total: 0});

    const saveToMerchant = async (data: any) => {
        console.log('Save to Merchant', data);
        const updated = await updateMerchant({
            ...merchant,
            id: merchant.id,
            orderlordId: data.id,
            orderlordVenueId: data.venue_id,
            latitude: data.latitude,
            longitude: data.longitude
        })
        if(updated) setMerchant(updated);
    }

    useEffect(() => {
        const getStores = async () => {
            const response = await getOrderlordStores(pagination);
            if(response) {
                console.log(response);
                const {data, ...paginationPayload} = response;
                if (paginationPayload.total !== pagination.total || paginationPayload.limit !== pagination.limit || paginationPayload.offset !== pagination.offset) {
                    setPagination(paginationPayload);
                }
                const venueData = data.map((record: { id: any; venue: {address: any; name: any; id: any; }; }) => ({
                        id: record.id,
                        name: record.venue.name,
                        latitude: record.venue.address?.lat,
                        longitude: record.venue.address?.lng,
                        venue_id: record.venue.id
                    }));
                setStores(venueData);
            }
        }
        getStores();
    }, [pagination])

    const bodyTemplate = (rowData: any, field: string) => {
        switch(field) {
            case 'actions':
                if (merchant.orderlordId === rowData.id) return (
                    <Button 
                        className="bg-red-500 text-whit p-3"
                        onClick={() => saveToMerchant({id: null, venue_id: null})}
                    >Disconnect</Button>
                );
                const connectedMerchant = merchants?.find((m) => m.orderlordId === rowData.id);
                return connectedMerchant ? <p className={'text-secondary-1'}>Connected To {connectedMerchant.name}</p> : <div className="flex">
                    <Button 
                        className="bg-primary text-whit p-3"
                        onClick={() => saveToMerchant(rowData)}
                    >Save To Merchant</Button>
                </div>
            default:
                return <p className={'text-secondary-1'}>{rowData[`${field}`]}</p>;
        }
    }
    const columns = [
        {field: 'id', header: 'ID'},
        {field: 'name', header: 'Name'},
        {field: 'venue_id', header: 'Venue ID'},
        {field: 'actions', header: 'Actions'}
    ]

    const setPage = async (event: any) => {
        console.log('Pagination Event', event);
        const paginationTemp = {limit: event.rows, offset: event.first, total: pagination.total || 0};
        setPagination(paginationTemp);
    }
    const syncOrders = async () => {
        if (merchant && !merchant.syncOrderlord) {
            setOpenSyncModal(true);
        } else if (merchant && merchant.id) {
            const newMerchant = await orderlordSyncMerchant(merchant.id, false, new Date().toISOString());
            setMerchant(newMerchant);
        }
    }

    return (<div className="card">
        {merchant && merchant.id && openSyncModal && <SyncOrdersModal merchantId={merchant.id} isOpen={openSyncModal} closeModal={() => setOpenSyncModal(false)} />}
        <div className="border-b border-gray-200 pb-5">
            {merchant.orderlordId && (<div className="mt-2">
                <h3 className="font-bold text-xl">Connected Store</h3>
                <div className="flex flex-row justify-between">
                    <table>
                        <tr>
                            <th className="text-left">StoreId</th>
                            <td>{merchant.orderlordId}</td>
                        </tr>
                        <tr>
                            <th className="text-left">VenueId</th>
                            <td>{merchant.orderlordVenueId}</td>
                        </tr>
                    </table>
                    <div className="flex">
                    <Button
                            type="button"
                            className={merchant.syncOrderlord ? "mr-2 relative inline-flex items-center rounded-md bg-red-500 border-red-500 border px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-transparent hover:text-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-500" : "mr-2 relative inline-flex items-center rounded-md bg-customgreen border-customgreen border px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-transparent hover:text-customgreen focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-customgreen"}
                            onClick={syncOrders}
                        >{merchant.syncOrderlord ? 'Orderlord NICHT syncen' : 'Orderlord syncen'}</Button>
                    <Button 
                        className="mr-2 relative inline-flex items-center rounded-md bg-red-500 border-red-500 border px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-transparent hover:text-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-500"
                        onClick={() => saveToMerchant({id: null, venue_id: null})}
                    >Disconnect</Button>
                    </div>
                </div>
            </div>)}
        </div>

        <DataTable 
            lazy
            value={stores || []}
            onPage={async (event) => await setPage(event)}
            paginator
            paginatorTemplate={`RowsPerPageDropdown PrevPageLink CurrentPageReport NextPageLink JumpToPageInput`}
            rowsPerPageOptions={[10, 25, 50, 100, 250, 500, 1000, 2500, 5000, 10000]}
            first={pagination.offset}
            totalRecords={pagination.total}
            rows={pagination.limit}
            emptyMessage="Keine Stores gefunden"
        >
            {columns.map(({field, header}) => (
                <Column 
                    key={field}
                    field={field}
                    header={header}
                    body={(rowData) => bodyTemplate(rowData, field)}
                />
            ))}
        </DataTable>
    </div>);
}
export default MerchantOrderlord;