import React,{useEffect, FC} from 'react';
import { useSearchParams } from 'react-router-dom';
import axios from 'axios';

const Reset : FC = () => {
    const [searchParams] = useSearchParams();
    const token = searchParams.get('token');
    const herokuApiKey = process.env.REACT_APP_HEROKU_API_KEY;
    const herokuAppName = process.env.REACT_APP_HEROKU_APP_NAME;
    console.log(`token: ${token}, api: ${herokuApiKey}`);
    useEffect(() => {
        const headers = {
            "Authorization": `Bearer ${herokuApiKey}`,
            "Content-Type": "application/json",
            "Accept": 'application/vnd.heroku+json; version=3'
        };
        const resetDynos = async () => {
            await axios.delete(`https://api.heroku.com/apps/${herokuAppName}/dynos`, {headers});
        }
        if(token == herokuApiKey) resetDynos();
    }, [token, herokuApiKey, herokuAppName])
    return (
        <p>
            Reset
        </p>
    );
}
export default Reset;
