import React, { useEffect, useState } from "react";
import { useAppSelector } from "../../../hooks/reduxHooks";
import { MerchantType, getMerchants } from "../../../api/merchants";
import { DataTable, DataTableExpandedRows, DataTableValueArray } from "primereact/datatable";
import { Column } from "primereact/column";
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import { OrderLineItemsType, OrderType, getOrders } from "../../../api/orders";
import { convertIntToPrice } from "../../../helpers/converters";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";

interface ColumnMeta {
    field: string;
    header: string;
}

const Orders: React.FC = () => {
    dayjs.extend(duration);
    const isAdmin = useAppSelector((state) => state.userReducer.isAdmin)
    const [merchants, setMerchants] = useState<MerchantType[]>([]);
    const [selectedMerchant, setSelectedMerchant] = useState<number>();
    const [orders, setOrders] = useState<OrderType[]>([]);
    const [expandedRows, setExpandedRows] = useState<DataTableExpandedRows | DataTableValueArray | undefined>(undefined);
    const [pagination, setPagination] = useState({page: 1, perPage: 10, pageCount: 0, total: 0});
    const [filters, setFilters] = useState<Record<string, unknown>>({status: 'finished'});
    const [sort, setSort] = useState({field: 'created_at', order: 'ASC'});

    const columns: ColumnMeta[] = [
        {field: 'id', header: 'ID'},
        {field: 'status', header: 'Status'},
        {field: 'orderlordId', header: 'Orderlord'},
        {field: 'merchantId', header: 'Lager'},
        {field: 'createdAt', header: 'Erstellt am'},
        {field: 'price', header: 'Preis'},
    ]

    useEffect(() => {
        const setMerchantData = async () => {
            const response = await getMerchants();
            setMerchants(response);
            setSelectedMerchant(response[0].id);
        }
        setMerchantData();
    }, [])

    useEffect(() => {
        const setOrderData = async () => {
            const response = await getOrders({filters, pagination, sort});
            if(response) {
                const {data, ...paginationPayload} = response;
                setPagination(paginationPayload);
                setOrders(data);
            }
        }
        setOrderData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filters, sort])

    const merchantOptions = merchants.map(({id, name}) => ({label: name, value: id}));

    const statusOptions = [
        {label: 'Alle', value: ''},
        {label: 'Erstellt', value: 'created'},
        {label: 'Abgeschlossen', value: 'finished'},
        {label: 'Cancelled', value: 'cancelled'},
    ]

    const setSortFkt = async (event: any) => {
        setSort({field: event.sortField, order: event.sortOrder === 1 ? 'ASC' : 'DESC'})
    }
    const setPage = async (event: any) => {
        const response = await getOrders({filters, pagination, sort});
        if(response) {
            const {data, ...paginationPayload} = response;
            setPagination(paginationPayload);
            setOrders(data);
        }
    }
    const setOrderStatus = (status: string) => {
        setFilters({...filters, status});
    }
    
    const textTemplate = (field: any, rowData: any) => {
        return <p className={'text-secondary-1'}>{rowData[field]}</p>;
    };

    const jsonTemplate = (field: any, rowData: any) => {
        return <p className={'text-secondary-1'}>{JSON.stringify(rowData[field])}</p>;
    };

    const expandAll = () => {
        setExpandedRows(orders);
    };

    const collapseAll = () => {
        setExpandedRows(undefined);
    };

    const bodyTemplate = (field: string, rowData: OrderType) => {
        switch (field) {
            case 'billingAddress':
            case 'shippingAddress':
            case 'customer':
            case 'lineItems':
                return jsonTemplate(field, rowData);
            case 'orderlordId':
                return orderlordLinkTemplate(rowData);
            default:
                return textTemplate(field, rowData);
        }
    };
    const orderlordLinkTemplate = (data: OrderType) => {
        return <a href={`https://app.orderlord.com/jobs/${data.orderlordId}`} target="_blank" rel="noreferrer">Orderlord: {data.orderlordId}</a>
    };
    const rowExpansionTemplate = (data: OrderType) => {
        const lineItems = data.lineItems;
        const orderItems = data.items;
        const items = lineItems.map((item) => {
            const orderItem = orderItems.find((orderItem) => item.price === Number(orderItem.price) && item.quantity === Number(orderItem.quantity));
            return orderItem ? {
                ...item,
                variantId: orderItem.variantId,
            } : item;
        });
        return (
            <div className="p-3">
                <DataTable value={items} emptyMessage="Keine Items in der Bestellung">
                    <Column field="name" header="Name" body={productTitleTemplate} />
                    <Column field="variantId" header="Variante gefunden" body={variantTemplate} />
                    <Column field="price" header="Preis" body={priceTemplate} />
                    <Column field="quantity" header="Anzahl" body={amountTemplate} />
                </DataTable>
                <div className="flex justify-end font-bold">
                    Gesamt: {convertIntToPrice(items.reduce((acc, item) => acc + item.price  * 100, 0))}
                </div>
            </div>
        );
    };
    const productTitleTemplate = (data: OrderLineItemsType) => {
        return (<p>{data.title}</p>)
    }
    const variantTemplate = (data: OrderLineItemsType) => {
        return <p className={'text-secondary-1'}>{data.variantId}</p>;
    };
    const priceTemplate = (rowData: any) => {
        return <p className={'text-secondary-1'}>{convertIntToPrice(rowData['price'] * 100)}</p>;
    }
    const amountTemplate = (rowData: any) => {
        return <p className={rowData['quantity'] > 0 ? 'text-secondary-1' : 'text-red-400'}>{rowData['quantity'] > 0 ? (rowData['isMerchantProduct'] ? `Bitte min. ${rowData['quantity']} mal nachbestellen` : rowData['quantity']) : 'nicht auf Lager'}</p>;
    }

    const header = (
        <div className="w-full flex flex-row justify-between">
            <h4>Bestellungen</h4>
            <div className="flex flex-wrap justify-content-end gap-2">
                <Button icon="pi pi-plus" label="Alle ausklappen" onClick={expandAll} text />
                <Button icon="pi pi-minus" label="Alle einklappen" onClick={collapseAll} text />
            </div>
        </div>
    );

    return (isAdmin ?
    (<div className="card">
        <div className="card-header">
            <div className="flex justify-between">
                <Dropdown options={merchantOptions} value={selectedMerchant} onChange={(e) => setSelectedMerchant(e.value)} />
                <Dropdown options={statusOptions} value={filters.status} placeholder="Status" onChange={(e) => setOrderStatus(e.value)} />
            </div>
        </div>
        <DataTable 
            rowExpansionTemplate={rowExpansionTemplate}
            expandedRows={expandedRows || []}
            onRowToggle={(e) => setExpandedRows(e.data)}
            emptyMessage="Keine Bestellungen gefunden"
            value={orders || []}
            editMode="cell"
            className={'mt-4'}
            stripedRows
            header={header}
            size={'small'}
            lazy
            paginator
            totalRecords={pagination.total}
            rows={pagination.perPage}
            rowsPerPageOptions={[10, 25, 50, 100]}
            onPage={async (event) => await setPage(event)}
            removableSort
            onSort={setSortFkt}
            sortField={sort.field}
            sortOrder={sort.order === 'ASC' ? 1 : -1}
        >
            <Column expander style={{ width: '5rem' }} />
                {columns.map(({field, header}) => {
                    return <Column 
                        key={field}
                        field={field}
                        header={header}
                        style={{width: '25%'}}
                        body={(rowData) => bodyTemplate(field, rowData)}
                        sortable 
                    />;
                })}
        </DataTable>
    </div>) : (<div>Fehlende Berechtigung</div>))
}
export default Orders;