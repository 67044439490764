export const categories = [
    'Bier',
    'Cannabis',
    'Convenience',
    'Gym & Gaming',
    'Haushalt',
    'Ice Cream',
    'Neu',
    'Party',
    'Snacks',
    'Softdrinks',
    'Spirituosen',
    'Sweets',
    'Tabak',
    'US Bakery',
    'Vapes',
    'Wein',
];