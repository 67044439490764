import React, { useCallback, useEffect, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column, ColumnEvent, ColumnEditorOptions } from "primereact/column";
import { createMerchantZipcodes, deleteMerchantZipcodes, getMerchantZipcodes, updateMerchantZipcodes } from "../../../../api/merchants";
import { InputNumber, InputNumberValueChangeEvent } from "primereact/inputnumber";
import { InputText } from "primereact/inputtext";
import { convertIntToPrice } from "../../../../helpers/converters";
import BulkZipcodeActionsModal from "./BulkZipcodeActionsModal";
import { ProgressSpinner } from "primereact/progressspinner";

interface props {
 isAdmin: boolean;
 merchantId: number;
}
const MerchantZipCodes: React.FC<props> = ({isAdmin, merchantId}) => {
    const [zipCodes, setZipCodes] = useState<any[]>();
    const [loading, setLoading] = useState<boolean>(false);
    const [csvModal, setCSVModal] = useState<boolean>(false);


    useEffect(() => {
        const setZipCodeData = async () => {
            const response = await getMerchantZipcodes(merchantId);
            if(response) {
                setZipCodes(response);
            }
        }
        setZipCodeData();
    }, [merchantId])


    const createZipCode = async () => {
        const newZipCode = {
            zipcode: '',
            city: '',
            minOrderAmount: 1900,
            deliveryFee: 285
        }
        const response = await createMerchantZipcodes(merchantId, newZipCode);
        if(response) {
            const newZipCodes = zipCodes ? [...zipCodes, response] : [response];
            setZipCodes(newZipCodes);
        }
    }

    const deleteZipCode = useCallback((id: number) => {
        const deleteZipCode = async () => {
            await deleteMerchantZipcodes(merchantId, id);
            const newZipCodes = zipCodes?.filter((zipcode) => zipcode.id !== id);
            setZipCodes(newZipCodes);
        };
        if(merchantId) deleteZipCode();
    }, [merchantId, zipCodes]) 


    const onCellEditComplete = async (e: ColumnEvent) => {
        let { rowData, newValue, field } = e;
        rowData[field] = newValue;
        if(merchantId && rowData.id !== '0') {
            const updatedZipCode = await updateMerchantZipcodes(merchantId, rowData.id, {
                zipcode: rowData.zipcode, 
                city: rowData.city,
                minOrderAmount: rowData.minOrderAmount,
                deliveryFee: rowData.deliveryFee
            });
            if(updatedZipCode) {
                const newZipcodes = zipCodes?.map((zipcode) => {
                    if(zipcode.id === `${updatedZipCode.id}`) return updatedZipCode;
                    return zipcode;
                })
                await setZipCodes(newZipcodes);
            }
        }
    };
    const stringEditor = (options: ColumnEditorOptions) => {
        return options ? <InputText value={options.value} onChange={(e) => {
            options.editorCallback?.(e.target.value);
        }} /> : (<></>);
    }

    const priceEditor = (options: ColumnEditorOptions) => {
        return options ? <InputNumber value={options.value} onValueChange={(e: InputNumberValueChangeEvent) => {
            const newValue = e.value && e.value > 0 ? e.value : 0;
            options.editorCallback?.(newValue);
        }} /> : (<></>);
    };
    const bodyTemplate = (rowData: any, field: string) => {
        switch(field) {
            case 'minOrderAmount':
            case 'deliveryFee':
                return <p className={'text-secondary-1'}>{convertIntToPrice(rowData[`${field}`])}</p>;
            case 'delete':
                return <button onClick={() => deleteZipCode(rowData.id)} className="text-red-500">Delete</button>;
            default:
                return <p className={'text-secondary-1'}>{rowData[`${field}`]}</p>;
        }
    }
    const columns = [
        {field: 'zipcode', header: 'PLZ', onCellEditComplete, editor: stringEditor},
        {field: 'city', header: 'Stadt', onCellEditComplete, editor: stringEditor},
        {field: 'minOrderAmount', header: 'Mindesbestellwert', onCellEditComplete, editor: priceEditor},
        {field: 'deliveryFee', header: 'Lieferkosten', onCellEditComplete, editor: priceEditor},
        {field: '', header: 'Mindesbestellwert JustEat', onCellEditComplete, editor: priceEditor},
        {field: '', header: 'Lieferkosten JustEat', onCellEditComplete, editor: priceEditor},
        {field: 'delete', header: ''}
    ]

    return (<div className="card">
        {merchantId && <BulkZipcodeActionsModal merchantId={merchantId} isOpen={csvModal} closeModal={() => setCSVModal(false)} setZipcodes={setZipCodes} zipcodes={zipCodes} setLoading={setLoading} />}
        <div className="border-b border-gray-200 pb-5">
            <div className="mt-2 flex items-center justify-between sm:flex-nowrap">
                <button
                    type="button"
                    className="mr-2 relative inline-flex items-center rounded-md bg-midblue border-midblue border px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-transparent hover:text-midblue focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-midblue"
                    onClick={() => setCSVModal(true)}
                >Bulk Aktionen</button>
                {loading && <ProgressSpinner style={{width: '50px', height: '50px'}} strokeWidth="8" />}
                <button 
                    onClick={createZipCode} 
                    className="mr-2 relative inline-flex items-center rounded-md bg-midblue border-midblue border px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-transparent hover:text-midblue focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-midblue"
                >PLZ hinzufügen</button>
            </div>
        </div>

        <DataTable 
            lazy
            value={zipCodes || []}
            editMode="cell"
            emptyMessage="Keine Zipcodes gefunden"
        >
            {columns.map(({field, header, onCellEditComplete, editor}) => (
                <Column 
                    key={field}
                    field={field}
                    header={header}
                    onCellEditComplete={onCellEditComplete}
                    editor={editor}
                    body={(rowData) => bodyTemplate(rowData, field)}
                />
            ))}
        </DataTable>
    </div>);
}
export default MerchantZipCodes;