import { createSlice } from "@reduxjs/toolkit";
import type {PayloadAction} from '@reduxjs/toolkit';
import { MerchantType } from "../../../api/merchants";

export interface IMerchantState {
    merchant: MerchantType | null;
    merchants: MerchantType[] | null;
}

const initialState: IMerchantState = {
    merchant: null,
    merchants: null,
}

export const merchantSlice = createSlice({
    name: 'merchant',
    initialState,
    reducers: {
        addMerchantData: (state, action: PayloadAction<MerchantType>) => {
            state.merchant = action.payload;
        },
        addMerchantsData: (state, action: PayloadAction<MerchantType[]>) => {
            state.merchants = action.payload;
        }, 
    },
});

export const {addMerchantData, addMerchantsData} = merchantSlice.actions;

export default merchantSlice.reducer;