export const ROUTES_DEFINITIONS = {
    Dashboard:'/',
    Login:'/login',
    RequestPassword:'/request-password',
    RestPassword:'/reset-password/:token',
    Merchants: '/merchants',
    MerchantOrders:'/merchant-orders',
    Products:'/products',
    Orders:'/orders',
    HelpDesk:'/help',
    Settings:'/settings',
    Reset:'reset'
}
