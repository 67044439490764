import { useState } from "react";
import { MerchantType, openCloseMerchant } from "../../../../api/merchants";
import EditMerchantModal from "../../../../components/Modals/EditMerchantModal";
import { ProgressSpinner } from "primereact/progressspinner";
import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { Fieldset } from "primereact/fieldset";

interface props {
    isAdmin: boolean;
    merchant: MerchantType;
    setMerchant: (merchant: MerchantType) => void;
   }

   const MerchantInfo: React.FC<props> = ({isAdmin, merchant, setMerchant}) => {
    const [editModal, setEditModal] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const setIsOpen = async () => {
        if(merchant && merchant.id) {
            setLoading(true);
            const newMerchant = await openCloseMerchant(merchant.id, !merchant.isOpen);
            if (newMerchant) setMerchant(newMerchant);
            setLoading(false);
        }
    }
    return (<div className="card">
        {merchant && isAdmin && <EditMerchantModal setLoading={setLoading} merchant={merchant} isOpen={editModal} closeModal={() => setEditModal(false)} setMerchant={setMerchant}/>}
        <div className="border-b border-gray-200 pb-5">
            <div className="flex items-center justify-between">
                <Card title={merchant.name} className="w-2/3">
                    <Fieldset legend="Vertragspartner">
                        <p>{merchant.contractor}</p>
                    </Fieldset>
                </Card>
                <div className="flex flex-col gap-2">
                    {loading && <ProgressSpinner />}
                    <Button
                            type="button"
                            className={`mr-2 relative inline-flex items-center rounded-md border px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-transparent  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 ${merchant.isOpen ? "bg-customgreen border-customgreen hover:text-customgreen focus-visible:outline-customgreen" : "bg-red-500 border-red-500 hover:text-red-500 focus-visible:outline-red-500"}`}
                            onClick={setIsOpen}
                        >{merchant.isOpen ? 'Lager Schließen' : 'Lager Öffnen'}</Button>
                    <Button 
                        className="mr-2 relative inline-flex items-center rounded-md bg-secondary-1 border-secondary-1 border px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-transparent hover:text-secondary-1 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-secondary-1"
                        onClick={() => setEditModal(true)}
                    >Edit Lager</Button>
                </div>
            </div>
        </div>
    </div>)
   }

   export default MerchantInfo;