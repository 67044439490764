import { useEffect, useState } from "react";
import { MerchantOrderItemType, MerchantOrderType, MerchantType, createMerchantOrder, fulfillMerchantOrder, getMerchantOrders, getMerchants, sendMerchantOrder, updateMerchantOrderItem } from "../../../api/merchants";
import { DataTable, DataTableExpandedRows, DataTableValueArray } from "primereact/datatable";
import { Column, ColumnEditorOptions, ColumnEvent } from "primereact/column";
import { convertIntToPrice } from "../../../helpers/converters";
import { InputNumber, InputNumberValueChangeEvent } from "primereact/inputnumber";
import { Dropdown } from "primereact/dropdown";
import { useAppSelector } from "../../../hooks/reduxHooks";

const MerchantOrders: React.FC = () => {
    const {isAdmin} = useAppSelector((state) => state.userReducer);
    const [merchants, setMerchants] = useState<MerchantType[]>([]);
    const [selectedMerchant, setSelectedMerchant] = useState<number>();
    const [orders, setOrders] = useState<MerchantOrderType[]>([]);
    const [orderStatus, setOrderStatus] = useState<string>();
    const [expandedRows, setExpandedRows] = useState<DataTableExpandedRows | DataTableValueArray | undefined>(undefined);

    useEffect(() => {
        const setMerchantData = async () => {
            const response = await getMerchants();
            setMerchants(response);
            setSelectedMerchant(response[0].id);
        }
        setMerchantData();
    }, [])

    useEffect(() => {
        const setOrderData = async () => {
            if (selectedMerchant) {
                const params = orderStatus ? {status: orderStatus} : {}
                const response = await getMerchantOrders(selectedMerchant, params);
                setOrders(response);
            } else {
                setOrders([]);
            }
        }
        setOrderData();
    }, [selectedMerchant, orderStatus])

    const merchantOptions = merchants.map(({id, name}) => ({label: name, value: id}));
    const statusOptions = [
        {label: 'Alle', value: ''},
        {label: 'Offen', value: 'created'},
        {label: 'In Bearbeitung', value: 'send'},
        {label: 'Versendet', value: 'shipped'},
        {label: 'Abgeschlossen', value: 'finished'},
    ]
    const dayOfWeek = (day: number) => {
        switch (day) {
            case 0: return 'So';
            case 1: return 'Mo';
            case 2: return 'Di';
            case 3: return 'Mi';
            case 4: return 'Do';
            case 5: return 'Fr';
            case 6: return 'Sa';
            default: return '??';
        }
    }
    const statusMapping = (status: string) => {
        switch (status) {
            case 'created':
                return 'Offen';
            case 'send':
                return 'In Bearbeitung';
            case 'shipped':
                return 'Versendet';
            case 'finished':
                return 'Abgeschlossen';
            default:
                return status;
        }
    }

    const productTitleTemplate = (data: MerchantOrderItemType) => {
        return (<p>{data.variant.product?.title}</p>)
    }
    const priceTemplate = (rowData: any) => {
        return <p className={'text-secondary-1'}>{convertIntToPrice(rowData['price'])}</p>;
    }
    const statusTemplate = (rowData: any) => {
        const status = rowData['status'];
        return <p className={'text-secondary-1'}>{statusMapping(status)}</p>;
    }
    const shippmentTemplate = (rowData: any) => {
        const status = rowData['status'];
        const merchant = merchants.find(({id}) => id === selectedMerchant);
        return status === 'created' ? <p className={'text-red-500'}>{'wird am ' + dayOfWeek(merchant?.reorderDay || 10) + ' erstellt'}</p> : <p></p>;
    }
    const totalTemplate = (rowData: any) => {
        return <p className={'text-secondary-1'}>{convertIntToPrice(rowData.items.reduce((acc: any, item: { price: any; }) => acc + item.price, 0))}</p>;
    }
    const amountTemplate = (rowData: any) => {
        const missingQuantity = rowData['wantedQuantity'] > 0 ? rowData['wantedQuantity'] - rowData['quantity'] : 0;
        const missingText = missingQuantity > 0 && rowData['isMerchantProduct'] ? `Bitte min. ${missingQuantity} mal nachbestellen` : missingQuantity > 0 ? 'nicht auf Lager' : rowData['quantity']; 
        return <p className={missingQuantity > 0 ? 'text-red-400' : 'text-secondary-1'}>{missingText}</p>;
    }

    const generateOrders = async () => {
        if(selectedMerchant) {
            await createMerchantOrder(selectedMerchant);
            await refetchOrders();
        }
    }

    const refetchOrders = async () => {
        if (selectedMerchant) {
            const params = orderStatus ? {status: orderStatus} : {}
            const response = await getMerchantOrders(selectedMerchant, params);
            setOrders(response);
        }
    }

    const onCellEditComplete = async (e: ColumnEvent, order: MerchantOrderType) => {
        let { rowData, newValue, field } = e;
        rowData[field] = newValue;
        if(selectedMerchant) {
            await updateMerchantOrderItem(selectedMerchant, order.id, rowData.id, rowData);
            await refetchOrders();
        }
    };

    const fullfillOrder = async (orderId: number) => {
        if(selectedMerchant) {
            await fulfillMerchantOrder(selectedMerchant, orderId);
            await refetchOrders();
        };
    }
    const sendOrder = async (orderId: number) => {
        await sendMerchantOrder(orderId);
    }

    const amountEditor = (options: ColumnEditorOptions) => {
        return options ? <InputNumber value={options.value} onValueChange={(e: InputNumberValueChangeEvent) => options.editorCallback?.(e.value)} /> : (<></>);
    };

    const orderActionTemplate = (data: MerchantOrderType) => {
        return data.status === 'shipped' ? (
            <div className="flex justify-end">
                <button className="btn btn-primary" onClick={() => fullfillOrder(data.id)}>Buchen</button>
            </div>
        ) : data.status === 'created' && isAdmin ? (
            <div className="flex justify-end">
                <button className="btn btn-primary" onClick={() => sendOrder(data.id)}>zu weclapp senden</button>
            </div>
        ) : (<></>);
    };

    const rowExpansionTemplate = (data: MerchantOrderType) => {
        return (
            <div className="p-3">
                <DataTable value={data.items} emptyMessage="Keine Items in der Bestellung">
                    <Column field="name" header="Name" body={productTitleTemplate} />
                    <Column field="price" header="Preis" body={priceTemplate} />
                    <Column field="quantity" header="Anzahl" body={amountTemplate} onCellEditComplete={(e) => onCellEditComplete(e, data)} editor={(options) => amountEditor(options)} />
                </DataTable>
                <div className="flex justify-end font-bold">
                    Gesamt: {convertIntToPrice(data.items.reduce((acc, item) => acc + item.price, 0))}
                </div>
            </div>
        );
    };

    const allowExpansion = (rowData: MerchantOrderType) => {
        return rowData.items.length > 0;
    };
    
    return (<div className="card">
        <div className="card-header">
            <div className="flex justify-between">
                <Dropdown options={merchantOptions} value={selectedMerchant} onChange={(e) => setSelectedMerchant(e.value)} />
                <Dropdown options={statusOptions} value={orderStatus} placeholder="Status" onChange={(e) => setOrderStatus(e.value)} />
                {selectedMerchant && <button className="btn btn-primary" onClick={generateOrders}>Bestellungen generieren</button>}
            </div>
        </div>

        <DataTable emptyMessage="Keine Bestellungen gefunden" rowExpansionTemplate={rowExpansionTemplate} expandedRows={expandedRows || []} onRowToggle={(e) => setExpandedRows(e.data)} value={orders} className="p-datatable-striped">
            <Column expander={allowExpansion} style={{ width: '5rem' }} />
            <Column field="id" header="ID" />
            <Column field="status" header="Status" body={statusTemplate} />
            <Column field="createdAt" header="Erstellt am" />
            <Column field="price" header="Preis" body={totalTemplate} />
            <Column field="" header="" body={shippmentTemplate} />
            <Column field="action" header="" body={orderActionTemplate} />
        </DataTable>
    </div>);
};
export default MerchantOrders;