import { getRequest } from ".";

export interface OrderType {
    id?: number;
    shopifyId?: string;
    billingAddress: Record<string, unknown>;
    shippingAddress: Record<string, unknown>;
    customer: Record<string, unknown>;
    lineItems: OrderLineItemsType[];
    items: OrderLineItemsType[];
    orderlordId?: number;
    merchantId?: number;
    cancelReason?: string;
    price: string;
    createdAt: Date;
    updatedAt: Date;
}

export interface OrderLineItemsType {
    title: string;
    sku: string;
    quantity: number;
    discounts: Record<string, unknown>[];
    unitPrice: number;
    price: number;
    variantId?: number;
}

export const getOrders = async (params?: Record<string, unknown>) => {
    const response = await getRequest('api/dashboard/orders', params) as any;
    const {data, pageCount, page, perPage, total} = response;
    return {data: data as OrderType[], pageCount, page, perPage, total};
}

export const fetchOrderlordOrders = async (merchantId: number, params?: Record<string, unknown>) => {
    const url = `api/dashboard/orders/${merchantId}/fetchOrders`;
    await getRequest(url, params) as any;
}