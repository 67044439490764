import {createSlice} from '@reduxjs/toolkit';
import type {PayloadAction} from '@reduxjs/toolkit';
export interface IUser {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  role: string
  merchantId: string;
}
export interface IUserState {
  user: IUser | null;
  isAdmin: boolean;
  isFranchiseeAdmin: boolean;
}

const initialState: IUserState = {
  user: null,
  isAdmin: false,
  isFranchiseeAdmin: false,
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    addUserData: (state, action: PayloadAction<IUser>) => {
      state.user = action?.payload;
      state.isAdmin = action?.payload?.role === 'admin';
      state.isFranchiseeAdmin = action?.payload?.role === 'admin' || action?.payload?.role === 'franchiseeAdmin';
    },
  },
});

export const {addUserData} = userSlice.actions;

export default userSlice.reducer;
