import {Fragment, FC} from 'react'
import {Dialog, Transition} from '@headlessui/react'
import {
    XMarkIcon,
} from '@heroicons/react/24/outline'
import {NavLink} from "react-router-dom";
import {ROUTES_DEFINITIONS} from "../../router/routerConfig";
import Logo from "../Logo";
import { useAppSelector } from '../../hooks/reduxHooks';

const navigation = [
    {name: 'Startseite', href: ROUTES_DEFINITIONS.Dashboard},
    {name: 'Produkte', href: ROUTES_DEFINITIONS.Products, isAdmin: true},
    {name: 'Lager', href: ROUTES_DEFINITIONS.Merchants},
    {name: 'Lager Bestellungen', href: ROUTES_DEFINITIONS.MerchantOrders},
    {name: 'Help Desk', href: ROUTES_DEFINITIONS.HelpDesk},
    {name: 'B2C Bestellungen', href: ROUTES_DEFINITIONS.Orders, isAdmin: true}
]
interface SidebarProps {
    setSidebarOpen: (status: boolean) => void;
    sidebarOpen: boolean
}
const Sidebar: FC<SidebarProps> = ({sidebarOpen, setSidebarOpen}) => {
    const {isAdmin} = useAppSelector((state) => state.userReducer);
    const filteredNavigation = navigation.filter((nav) => nav.isAdmin ? isAdmin : true);
    return (
        <div>
            <Transition.Root show={sidebarOpen} as={Fragment}>
                <Dialog as="div" className="relative z-50 lg:hidden" onClose={setSidebarOpen}>
                    <Transition.Child
                        as={Fragment}
                        enter="transition-opacity ease-linear duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="transition-opacity ease-linear duration-300"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0">
                        <div className="fixed inset-0 bg-gray-900/80"/>
                    </Transition.Child>

                    <div className="fixed inset-0 flex">
                        <Transition.Child
                            as={Fragment}
                            enter="transition ease-in-out duration-300 transform"
                            enterFrom="-translate-x-full"
                            enterTo="translate-x-0"
                            leave="transition ease-in-out duration-300 transform"
                            leaveFrom="translate-x-0"
                            leaveTo="-translate-x-full"
                        >
                            <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
                                <Transition.Child
                                    as={Fragment}
                                    enter="ease-in-out duration-300"
                                    enterFrom="opacity-0"
                                    enterTo="opacity-100"
                                    leave="ease-in-out duration-300"
                                    leaveFrom="opacity-100"
                                    leaveTo="opacity-0">
                                    <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                                        <button type="button" className="-m-2.5 p-2.5"
                                                onClick={() => setSidebarOpen(false)}>
                                            <span className="sr-only">Close sidebar</span>
                                            <XMarkIcon className="h-6 w-6 text-primary" aria-hidden="true"/>
                                        </button>
                                    </div>
                                </Transition.Child>
                                {/* Sidebar component, swap this element with another sidebar if you like */}
                                <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-primary px-6 pb-4">
                                    <div className="mt-6 flex justify-start">
                                        <Logo  className={'w-32'}/>
                                    </div>
                                    <nav className="flex flex-1 flex-col">
                                        <ul className="flex flex-1 flex-col gap-y-7">
                                            <li>
                                                <ul className="-mx-2 space-y-1">
                                                    {filteredNavigation.map((item) => (
                                                        <li key={item.name}>
                                                            <NavLink
                                                                to={item.href}
                                                                className={({isActive}) => {
                                                                    const style = isActive ? "bg-green text-secondary-2" : "text-secondary-1"
                                                                    return style + " group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                                                                }}>
                                                                {item.name}
                                                            </NavLink>
                                                        </li>
                                                    ))}
                                                </ul>
                                            </li>
                                            <li className="mt-auto">
                                                <NavLink
                                                    to="/settings"
                                                    className={({isActive}) =>
                                                        (isActive ? "text-secondary-2" : "text-secondary-1") + " group -mx-2 flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6"
                                                    }>
                                                    Einstellungen
                                                </NavLink>
                                            </li>
                                        </ul>
                                    </nav>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </Dialog>
            </Transition.Root>
            {/* Static sidebar for desktop */}
            <div className="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-72 lg:flex-col">
                {/* Sidebar component, swap this element with another sidebar if you like */}
                <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-primary px-6 pb-4">
                    <div className="mt-6 flex justify-start">
                       <Logo />
                    </div>
                    <nav className="flex flex-1 flex-col">
                        <ul className="flex flex-1 flex-col gap-y-7">
                            <li>
                                <ul className="-mx-2 space-y-1">
                                    {filteredNavigation.map((item) => (
                                        <li key={item.name}>
                                            <NavLink
                                                to={item.href}
                                                className={({isActive}) =>
                                                    (isActive ? "bg-green text-secondary-2" : "text-secondary-1 hover:text-white hover:bg-secondary-1 ") + " group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                                                }>
                                                {item.name}
                                            </NavLink>
                                        </li>
                                    ))}
                                </ul>
                            </li>

                            <li className="mt-auto">
                                <NavLink
                                    to={ROUTES_DEFINITIONS.Settings}
                                    className={({isActive}) =>
                                        (isActive ? "bg-green text-secondary-2" : "text-secondary-1 hover:text-white hover:bg-secondary-1 ") + " group -mx-2 flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6"
                                    }>
                                    Einstellungen
                                </NavLink>
                            </li>
                        </ul>
                    </nav>
                </div>
            </div>
        </div>
    );
}
export default Sidebar
